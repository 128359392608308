import { useEffect } from "react";
import ReactGA from "react-ga4";

const isProd = process.env.REACT_APP_ENVIRONMENT === "PRD";
const isAcc = process.env.REACT_APP_ENVIRONMENT === "ACC";

const gaOptions = {
  cookie_flags: "SameSite=None;Secure",
};

const gtagOptions = {
  cookie_flags: "SameSite=None;Secure",
};

export const IntitializeGA = () => {
  if (isProd) {
    const TRACKING_ID = "G-4WN887XH55"; // OUR_TRACKING_ID
    ReactGA.initialize([
      {
        trackingId: TRACKING_ID,
        gaOptions: gaOptions,
        gtagOptions: gtagOptions,
      },
    ]);
  }

  if (isAcc) {
    const TRACKING_ID = "G-91ES312BTX"; // OUR_TRACKING_ID
    ReactGA.initialize([
      {
        trackingId: TRACKING_ID,
        gaOptions: gaOptions,
        gtagOptions: gtagOptions,
      },
    ]);
  }
};

export const SendArticlePage = (data) => {
  useEffect(() => {
    if (isProd || isAcc) {
      if (data) {
        ReactGA.send({
          hitType: "pageview",
          page: window.location.pathname,
          title: data.title,
        });
      }
    }
  }, [data]);
};
