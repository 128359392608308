import { RevImg64x64 } from "../../components/common/RevImg";
import { Link } from "react-router-dom";
import { DangerousDiv } from "../../components/dangerousGerneric";

const CmsIndexArticle = ({ article }) => {
  const MaintenanceCosts = ({ article }) => {
    const data = article.contentRequiresAccount
      ? []
      : JSON.parse(article.internalDescription);
    return (
      <div
        className={`media-content ${
          article.contentRequiresAccount ? "rev-authorized-content" : ""
        }`}
      >
        <div className="content">
          <div>
            <strong>{article.title}</strong>
            <br />
            {data.name}
            <br />
            {data.unit && (
              <>
                <strong>eenheid:</strong>{" "}
                <DangerousDiv content={data.unit} className="content" />
                &nbsp;
              </>
            )}
            <strong>cyclus:</strong> {data.cycle}&nbsp;
            <strong>kosten:</strong>€ {data.price}
          </div>
        </div>
      </div>
    );
  };

  const NormalSearchResults = ({ article }) => {
    return (
      <div
        className={`media-content ${
          article.contentRequiresAccount ? "rev-authorized-content" : ""
        }`}
      >
        <div className="content">
          <p>
            <strong>{article.title}</strong>
            <br />
            {article.metaDescription}
            {article.metaDescription && <br />}
            {`~${article.cmsPath}`}
          </p>
        </div>
      </div>
    );
  };

  return (
    <Link to={article.cmsPath}>
      <div className="box">
        <article className="media">
          {article.image && (
            <div className="media-left">
              <RevImg64x64
                src={article.image.src}
                alt={article.image.altText}
              />
            </div>
          )}
          {article.type !== 3 && <NormalSearchResults article={article} />}
          {article.type === 3 && <MaintenanceCosts article={article} />}
        </article>
      </div>
    </Link>
  );
};

export default CmsIndexArticle;
