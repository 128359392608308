import RevHelmet from "./RevHelmet";
import { useLocation } from "react-router";
import { useEffect } from "react";
import * as states from "../redux/states";
import * as pages from "../navigation";

import {
  loadSeoMetaData,
  selectSeoMetaDataForPath,
  selectSeoMetaDataStatus,
} from "../redux/slices/seoMetaDataSlice";
import { useDispatch, useSelector } from "react-redux";
import { SendArticlePage } from "./GAManager";

const RevHelmetManager = () => {
  const location = useLocation();
  const dispatcher = useDispatch();
  const state = useSelector(selectSeoMetaDataStatus);
  useEffect(() => {
    if (state === states.idle) {
      dispatcher(loadSeoMetaData());
    }
  }, [state, dispatcher]);

  const data = useSelector((state) => {
    let tempData = selectSeoMetaDataForPath(state, location.pathname);
    if (!tempData) {
      tempData = selectSeoMetaDataForPath(state, pages.home);
    }
    return tempData;
  });

  SendArticlePage(data);

  return <RevHelmet data={data} />;
};

export default RevHelmetManager;
