import { Link } from "react-router-dom";
import { RevRoundNumber } from "../../modules/revCalculation";
import * as links from "../../externalLinks";
import * as pages from "../../navigation";
import { useNavigate } from "react-router-dom";

const CmsIndexTable = ({ articles }) => {
  const navigate = useNavigate();
  const toUrlClick = (url) => {
    navigate(url);
  };
  const toShopClick = () => {
    window.location.href = links.shopAbonnement;
  };
  const TableRow = ({ article }) => {
    const data = article.contentRequiresAccount
      ? {}
      : JSON.parse(article.internalDescription);
    return (
      <>
        {article.contentRequiresAccount && (
          <tr onClick={toShopClick} className="is-clickable">
            <td className="rev-authorized-content">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a>{article.title}</a>
            </td>
            <td className="rev-authorized-content" colSpan="3" width="100%">
              {article.metaDescription}
            </td>
            <td className="has-text-primary" colSpan={"4"}>
              Sluit een abonnement af
            </td>
          </tr>
        )}
        {!article.contentRequiresAccount && (
          <tr
            onClick={() => toUrlClick(article.cmsPath)}
            className="is-clickable"
          >
            <td>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a>{article.title}</a>
            </td>
            <td>{data.name}</td>
            <td
              dangerouslySetInnerHTML={{
                __html: data.unit,
              }}
            ></td>
            <td>{data.cycle}</td>
            <td className="has-text-right">{RevRoundNumber(data.price)}</td>
            <td>{data.remarks}</td>
          </tr>
        )}
      </>
    );
  };

  return (
    <div className="container">
      <Link className="content" to={pages.toelichtingOnderhoud}>
        Toelichting disclaimer
      </Link>
      <div className="table-container">
        <table
          className="table rev-table-newproject is-striped is-hoverable mt-4"
          width="100%"
        >
          <thead>
            <tr className="has-background-primary">
              <th className="has-text-white-bis">Onderhoudscode</th>
              <th className="has-text-white-bis">Onderhoudshandeling</th>
              <th className="has-text-white-bis">Eenheid</th>
              <th className="has-text-white-bis">Cyclus</th>
              <th className="has-text-white-bis has-text-right">
                Prijs&nbsp;€
              </th>
              <th className="has-text-white-bis">Opmerkingen</th>
            </tr>
          </thead>
          <tbody>
            {articles &&
              articles.map((article) => {
                return <TableRow article={article} key={article.title} />;
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CmsIndexTable;
