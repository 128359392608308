import Loader from "../../components/loader";
import CmsIndexArticle from "../cms/cmsIndexArticle";
import CmsIndexTable from "../cms/cmsIndexTable";

const KengetallenScrollPage = ({
  articles,
  categories,
  sentryRef,
  hasNextPage,
  loading,
}) => {
  const show = articles.length > 0;

  return (
    <>
      {show && (
        <section className="section pt-0">
          <div className="container">
            <div className={`is-hidden-mobile`}>
              <CmsIndexTable articles={articles} />
            </div>
            <div className={`is-hidden-desktop is-hidden-tablet`}>
              {articles.map((article) => {
                return (
                  <CmsIndexArticle
                    article={article}
                    key={article.id}
                    categories={categories}
                  />
                );
              })}
            </div>
            {(loading || hasNextPage) && (
              <div ref={sentryRef}>
                <Loader />
              </div>
            )}
          </div>
        </section>
      )}
    </>
  );
};

export default KengetallenScrollPage;
