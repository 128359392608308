import { useEffect, useState } from "react";
import * as events from "../../custom-scripts/custom-javascript-events";
import { useSelector } from "react-redux";
import * as states from "../../redux/states";
import SearchBar from "../../components/searchBar";
import {
  fetchCmsContentFilters,
  fetchCmsContentIndexScroll,
  selectCmsContentCategoriesByType,
  selectCmsContentCategoriesState,
  selectCmsContentIndexByType,
  selectCmsContentIndexFilterByType,
  selectCmsContentIndexStateByType,
} from "../../redux/slices/cmsTypeContentIndexSlice";
import Loader from "../../components/loader";
import KengetallenContent from "./kengetallenContent";
import useInfiniteScroll from "react-infinite-scroll-hook";
import KengetallenScrollPage from "./kengetallenScrollPage";
import RevHeader from "../../components/header";

const KengetallenSelectionManager = ({ dispatcher }) => {
  const cmsType = "kengetallen";
  const categoryState = useSelector(selectCmsContentCategoriesState);
  const ready = categoryState === states.completed;
  useEffect(() => {
    if (categoryState === states.idle) {
      dispatcher(fetchCmsContentFilters());
    }
  }, [categoryState, dispatcher]);

  useEffect(() => {
    const customEventNoCopy = new Event(events.eventPreventCopyAmounts);
    document.dispatchEvent(customEventNoCopy);
  });

  const DetermineShouldShow = (filter, categoryList) => {
    const threeCharacters = filter.trim().length > 2;
    const categorySelected = categoryList.find((x) => x.value);
    setShouldShow(threeCharacters || categorySelected !== undefined);
  };

  const searchOnChange = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    setFilter(lowerCase);
    DetermineShouldShow(lowerCase, categoryList);
    setState(states.searchChanged);
  };

  const [filter, setFilter] = useState(
    useSelector((state) => {
      return selectCmsContentIndexFilterByType(state, cmsType);
    })
  );

  const tempstate = useSelector((state) => {
    return selectCmsContentIndexStateByType(state, cmsType);
  });

  const [shouldShow, setShouldShow] = useState();
  const [state, setState] = useState(tempstate);
  const index = useSelector((state) => {
    return selectCmsContentIndexByType(state, cmsType);
  });

  const [categoryList, setCategories] = useState();
  const tempCategories = useSelector((state) => {
    return selectCmsContentCategoriesByType(state, cmsType);
  });

  useEffect(() => {
    if (ready && !categoryList) {
      // create deep copy to unscrew the redux shizzle
      const serializedList = JSON.stringify(tempCategories);
      setCategories(JSON.parse(serializedList));
    } else if (ready && categoryList && shouldShow === undefined) {
      DetermineShouldShow(filter, categoryList);
    }
    if (state !== tempstate) {
      setState(tempstate);
    }
  }, [
    state,
    tempstate,
    tempCategories,
    ready,
    categoryList,
    shouldShow,
    filter,
  ]);

  const filterListOnChange = (e) => {
    setFilter("");
    const index = categoryList.findIndex((obj) => obj.name === e.target.text);
    let tempFilters = [...categoryList];
    let oldValue = categoryList[index];
    tempFilters[index] = { name: oldValue.name, value: !oldValue.value };
    setCategories(tempFilters);
    setState(states.searchChanged);
    DetermineShouldShow(filter, tempFilters);
  };

  useEffect(() => {
    if (state === states.idle && shouldShow) {
      dispatcher(
        fetchCmsContentIndexScroll({
          cmsType: cmsType,
          filter: filter,
          categories: categoryList,
          continueFromCurrentRecord: state !== states.searchChanged,
        })
      );
    } else if (state === states.searchChanged && shouldShow) {
      const timer = setTimeout(() => {
        dispatcher(
          fetchCmsContentIndexScroll({
            cmsType: cmsType,
            filter: filter,
            categories: categoryList,
            continueFromCurrentRecord: false,
          })
        ).then(() => {
          return () => clearTimeout(timer);
        });
      }, 600);
    }
  }, [dispatcher, index, filter, state, categoryList, shouldShow]);

  // add infinity loading page functions
  const loadMore = () => {
    dispatcher(
      fetchCmsContentIndexScroll({
        cmsType: cmsType,
        filter: filter,
        categories: categoryList,
        continueFromCurrentRecord: state !== states.searchChanged,
      })
    );
  };

  const loading = state === states.loading;
  const hasNextPage = state !== states.completed;
  const [sentryRef] = useInfiniteScroll({
    loading: loading,
    hasNextPage: hasNextPage,
    onLoadMore: loadMore,
    error: state === states.failed,
    rootMargin: "0px 0px 400px 0px",
  });

  return (
    <>
      <RevHeader title={"Kengetallen"} />
      <SearchBar searchOnChange={searchOnChange} startValue={filter} />
      {ready && categoryList && (
        <KengetallenContent
          categories={categoryList}
          filterListOnChange={filterListOnChange}
        />
      )}
      {!ready && <Loader center={true} />}
      {ready && index && shouldShow && (
        <KengetallenScrollPage
          sentryRef={sentryRef}
          loading={loading}
          hasNextPage={hasNextPage}
          articles={index}
          categories={categoryList}
          filterListOnChange={filterListOnChange}
        />
      )}
    </>
  );
};

export default KengetallenSelectionManager;
