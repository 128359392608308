import { useEffect, useState } from "react";
import { client } from "../../../api/client";

export const OzbEnRiool = () => {
  const [ozbData, setOzbData] = useState();

  const openClass = "rev-toggle-open";
  const closeClass = "rev-toggle-close";

  useEffect(() => {
    const fetchData = async () => {
      await client
        .get("api/cmscontent/GetOzbEnRiool")
        .then((d) => {
          setOzbData(d.data);
        })
        .catch((e) => console.error(e));
    };
    fetchData();
  }, []);

  // javascript function added to maintain
  const toggleBox = (e) => {
    const row = document.getElementById(`${e?.target?.dataset?.target}`);
    if (row) {
      row.classList.toggle(openClass);
      row.classList.toggle(closeClass);
    }
  };

  const width = "70px";

  const TH = (props) => {
    return (
      <th
        height="330px"
        style={{
          maxWidth: width,
          verticalAlign: "bottom",
          position: "relative",
          top: "0px",
        }}
      >
        {props.children}
      </th>
    );
  };

  return (
    <div className="table-container" style={{ width: "fitContent" }}>
      <table className="rev-css-toggle-box">
        <thead>
          <tr>
            <TH />
            <TH />
            {ozbData &&
              ozbData.rates.map((x) => (
                <TH key={x.id}>
                  <div
                    style={{
                      transform: "rotate(-90deg)",
                      whiteSpace: "nowrap",
                      textAlign: "right",
                      padding: "5px",
                      fontSize: "16px",
                    }}
                  >
                    {x.name}
                  </div>
                </TH>
              ))}
          </tr>
        </thead>

        {ozbData &&
          ozbData.provincies.map((d) => {
            return (
              <tbody key={d.name}>
                <tr
                  className={closeClass}
                  id={`row-${d.name}`}
                  onClick={toggleBox}
                  data-target={`row-${d.name}`}
                >
                  <td
                    colSpan={ozbData.rates.length + 2}
                    data-target={`row-${d.name}`}
                  >
                    <label data-target={`row-${d.name}`}>{d.name}</label>
                  </td>
                </tr>
                {d.gemeentes.map((g) => {
                  return (
                    <tr key={`${d.name}_${g.name}`} className="rev-toggle-row">
                      <td></td>
                      <td>{g.name}</td>
                      {g.rates.map((r) => (
                        <td
                          className="has-text-right"
                          key={`${d.name}_${g.name}_${r.id}`}
                          width={width}
                          style={{
                            maxWidth: width,
                            fontSize: "16px",
                            paddingLeft: "5px",
                            paddingRight: "5px",
                          }}
                        >
                          {r.rate}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            );
          })}
      </table>
    </div>
  );
};
